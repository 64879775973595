import React, { useState } from "react";

import { LoginCard } from "@wayhome-uk/components";
import { useAuth } from "@wayhome-uk/utils";
import { Redirect } from "react-router-dom";

export const LoginPage: React.FC = () => {
    const { isLoggedIn, signIn } = useAuth();
    const [status, setStatus] = useState<number | undefined>(undefined);

    const handleSubmit = async (values: { email: string; password: string }) => {
        const status = await signIn(values);
        setStatus(status);
    };

    return isLoggedIn ? (
        <Redirect to="/" />
    ) : (
        <LoginCard title="Email Scheduler | Sign In" onSubmit={handleSubmit} status={status} />
    );
};
