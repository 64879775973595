import { date, mixed, object, string } from "yup";

export const sendGridValidationSchema = object().shape({
    customerIds: string().required("You must enter at least 1 UUID in the ‘Customer UUIDs’ field"),
    emailTemplateId: string().required("You must enter a Sendgrid Template ID"),
    scheduleDate: date().required("You must choose a time to send the emails"),
    tags: string().required("You must specify tags for the emails"),
    categories: string().required("You must specify categories for the emails"),
    unsubGroup: mixed<number | null>().required("You must specify the unsubscribe group ID"),
});
