import React, { useState } from "react";

import {
    Button,
    ErrorMessage,
    Header4,
    Input,
    InputExplanation,
    Label,
    PageWidth,
    ShortParagraph,
    ShortParagraphCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { post } from "@wayhome-uk/utils";
import { Formik } from "formik";
import styled from "styled-components";

import { FormDatePicker } from "components/form-date-picker";

import {
    EmailSchedulerConfirmation,
    EmailSchedulerErrorMessage,
    EmailSchedulerProcessingMessage,
    EmailSchedulerSuccessMessage,
} from "./email-scheduler-popups";
import { sendGridValidationSchema } from "./email-scheduler-validation-schema";

interface DataProps {
    emailTemplateId: string;
    scheduleDate: string;
    customerIds: string;
    priority: number | null;
    tags: string;
    categories: string;
    unsubGroup: number | null;
    shouldIgnoreSubPrefs: boolean;
}

const initialDataValues: DataProps = {
    emailTemplateId: "",
    scheduleDate: "",
    customerIds: "",
    priority: null,
    tags: "",
    categories: "",
    unsubGroup: null,
    shouldIgnoreSubPrefs: false,
};

export const EmailScheduler: React.FC = () => {
    const token = localStorage.getItem("token");
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
    const [data, setData] = useState<DataProps>(initialDataValues);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showProcessingMessage, setShowProcessingMessage] = useState<boolean>(false);

    const cancelSchedulingEmails = () => {
        setShowConfirmationPopup(false);
    };

    const handleSubmit = (values: DataProps) => {
        setData(values);
        setShowConfirmationPopup(true);
    };

    const refreshPageOnSuccessfulPost = () => {
        window.location.reload();
    };

    const handleCloseErrorMessage = () => {
        setShowErrorMessage(false);
    };

    const dataToPost = (customerID: string, categories: string[], tags: string[]) => {
        const isBST = new Date().getTimezoneOffset() < 0;
        const scheduledDate = isBST ? `${data.scheduleDate}+0100` : `${data.scheduleDate}+0000`;

        return {
            scheduledFor: scheduledDate,
            type: "templated-email",
            contacts: [{ ID: customerID, source: "customer" }],
            tags: tags,
            priority: data.priority || undefined,
            context: {
                template_id: data.emailTemplateId,
                categories: categories,
                asm_group_id: data.unsubGroup,
                bypass_list_management: {
                    enable: data.shouldIgnoreSubPrefs,
                },
            },
        };
    };

    const onSubmit = async (customerIDs: string[]) => {
        const { categories, tags } = data;
        const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v0/emails/bulk`;
        const categoriesArr = categories.length ? categories.replace(/\s+/g, "").split(",") : [];
        const tagsArr = tags.length ? tags.replace(/\s+/g, "").split(",") : [];
        const postBody = { emails: customerIDs.map((ID) => dataToPost(ID, categoriesArr, tagsArr)) };

        const res = await post(API_ENDPOINT, postBody, token);
        return res.ok;
    };

    const acceptSchedulingEmails = async () => {
        setShowConfirmationPopup(false);
        setShowProcessingMessage(true);

        const customerIDs = data.customerIds.match(/[^\r\n]+/g) || [""];
        const postSuccess = await onSubmit(customerIDs);

        if (postSuccess) {
            setShowProcessingMessage(false);
            setShowSuccessMessage(true);
        } else {
            setShowProcessingMessage(false);
            setShowErrorMessage(true);
        }
    };

    return (
        <div>
            <PageWidth>
                <Formik
                    initialValues={initialDataValues}
                    validationSchema={sendGridValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <>
                            <FormWrapper>
                                <FormContainer onSubmit={props.handleSubmit}>
                                    <Heading as="h1">Test & Live Email Scheduler</Heading>
                                    <ShortParagraph>
                                        This email scheduler can be used to to schedule marketing emails for test
                                        purposes, and sending to live customers. If you are scheduling an email for test
                                        purposes, use “testing” in the Categories field
                                    </ShortParagraph>

                                    <VerticalSpacing size={24} />
                                    <Section>
                                        <StyledLabel>Sendgrid Template ID:</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            data-testid="emailTemplateId"
                                            name="emailTemplateId"
                                            onChange={props.handleChange}
                                        />
                                    </Section>
                                    <Section>
                                        <StyledLabel>Customer UUIDs:</StyledLabel>
                                        <TextAreaInput
                                            data-testid="customerIds"
                                            name="customerIds"
                                            onChange={props.handleChange}
                                            as="textarea"
                                            placeholder="Please paste a list of UUIDs here, one on each line"
                                        />
                                    </Section>
                                    <Section>
                                        <StyledLabel>Scheduled Time:</StyledLabel>
                                        <FormDatePicker
                                            name="scheduleDate"
                                            placeholderText="Click to select a date"
                                            value={props.values.scheduleDate}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            onChange={(id, value) => props.setFieldValue(id, value)}
                                        />
                                    </Section>
                                    <Section>
                                        <StyledLabel htmlFor="priority">Priority</StyledLabel>
                                        <StyledInput
                                            type="number"
                                            id="priority"
                                            name="priority"
                                            onChange={props.handleChange}
                                        />
                                    </Section>
                                    <Section>
                                        <StyledLabel htmlFor="tags">Tags (comma separated)</StyledLabel>
                                        <StyledInputExplanation>
                                            Appears on the backend only. Used to prevent sending duplicate emails to the
                                            same user. Use ‘test’ or if sending more than one email in a row, something
                                            like ‘test-a' and ‘test-b’.
                                        </StyledInputExplanation>
                                        <StyledInput type="text" id="tags" name="tags" onChange={props.handleChange} />
                                    </Section>
                                    <Section>
                                        <StyledLabel htmlFor="categories">Categories (comma separated)</StyledLabel>
                                        <StyledInputExplanation>
                                            Appears in SendGrid and can be used to filter our performance stats. Use
                                            ‘testing’ for emails sent using this scheduler
                                        </StyledInputExplanation>
                                        <StyledInput
                                            type="text"
                                            id="categories"
                                            name="categories"
                                            onChange={props.handleChange}
                                        />
                                    </Section>
                                    <Section>
                                        <StyledLabel htmlFor="unsub-group">Unsubscribe group</StyledLabel>
                                        <StyledInput
                                            type="number"
                                            id="unsub-group"
                                            name="unsubGroup"
                                            onChange={props.handleChange}
                                        />
                                    </Section>
                                    <CheckBoxWrapper>
                                        <StyledCheckBox
                                            type="checkbox"
                                            id="is-ignore-unsub"
                                            name="shouldIgnoreSubPrefs"
                                            onChange={props.handleChange}
                                        />
                                        <StyledLabel htmlFor="is-ignore-unsub">
                                            Ignore unsubscription preferences?
                                        </StyledLabel>
                                    </CheckBoxWrapper>
                                    <VerticalSpacing size={16} />
                                    {Object.keys(props.errors).length > 0 && (
                                        <StyledErrorMsg>
                                            <ul>
                                                {Object.keys(props.errors).map((error, index) => (
                                                    <li key={index}>{props.errors[error]}</li>
                                                ))}
                                            </ul>
                                        </StyledErrorMsg>
                                    )}
                                    <Button data-testid="submit-btn" type="submit">
                                        Send Emails
                                    </Button>
                                </FormContainer>
                            </FormWrapper>
                        </>
                    )}
                </Formik>
                {showConfirmationPopup && (
                    <ModalWrapper>
                        <EmailSchedulerConfirmation
                            onClickCancel={cancelSchedulingEmails}
                            onClickOk={acceptSchedulingEmails}
                            scheduleDate={data.scheduleDate}
                            customerIds={data.customerIds}
                        />
                    </ModalWrapper>
                )}
                {showSuccessMessage && (
                    <ModalWrapper>
                        <EmailSchedulerSuccessMessage onClickOk={refreshPageOnSuccessfulPost} />
                    </ModalWrapper>
                )}
                {showErrorMessage && (
                    <ModalWrapper>
                        <EmailSchedulerErrorMessage onClickOk={handleCloseErrorMessage} />
                    </ModalWrapper>
                )}
                {showProcessingMessage && (
                    <ModalWrapper>
                        <EmailSchedulerProcessingMessage />
                    </ModalWrapper>
                )}
            </PageWidth>
        </div>
    );
};

const FormWrapper = styled.div`
    display: grid;
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-self: left;
    background: ${(props) => props.theme.grey200};
    padding: ${({ theme }) => theme.spacing24} ${({ theme }) => theme.spacing40};
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
`;

const TextAreaInput = styled(Input)`
    padding: 15px;
    width: 100%;
    height: 200px;
    outline: none;
`;

const StyledInput = styled(Input)`
    outline: none;
    width: 100%;
`;

const StyledLabel = styled(Label)`
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing4};
`;

const StyledInputExplanation = styled(InputExplanation)`
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    padding: 0 0 20px;
    outline: none;
`;

const Heading = styled(Header4)`
    margin: 20px 0;
`;

const StyledErrorMsg = styled(ErrorMessage)`
    ${ShortParagraphCss};
    margin-bottom: ${({ theme }) => theme.spacing24};

    svg {
        display: none;
    }
`;

const StyledCheckBox = styled.input`
    appearance: revert;
    margin-right: ${({ theme }) => theme.spacing8};
`;

const CheckBoxWrapper = styled(Section)`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const ModalWrapper = styled.div`
    display: grid;
    place-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
`;
