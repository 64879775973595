import React, { Component } from "react";

import { ShortParagraphCss } from "@wayhome-uk/design-system";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

export interface Props {
    className?: string;
    name: string;
    value?: string;
    onChange: (ID: string, value: string) => void;
    showTimeSelect?: boolean;
    dateFormat?: string;
    timeIntervals?: number;
    timeCaption?: string;
    timeFormat?: string;
    placeholderText?: string;
}

export class FormDatePicker extends Component<Props> {
    handleChange = (date: Date) => {
        const { onChange, name, showTimeSelect } = this.props;
        const dateFormat = showTimeSelect ? "yyyy-MM-dd'T'HH:mm:ss" : "yyyy-MM-dd";
        const dateString = format(date, dateFormat);
        onChange(name, dateString);
    };

    render() {
        const {
            className,
            value,
            showTimeSelect,
            timeIntervals,
            timeCaption,
            timeFormat,
            dateFormat,
            placeholderText,
        } = this.props;
        const selectedValue = value ? new Date(value) : null;
        const getDateFormat = dateFormat ? dateFormat : "dd/MM/yyyy";

        return (
            <StyledDatePicker
                className={className}
                onChange={this.handleChange}
                dateFormat={getDateFormat}
                selected={selectedValue}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
                timeFormat={timeFormat}
                placeholderText={placeholderText}
            />
        );
    }
}

const StyledDatePicker = styled(DatePicker)`
    ${ShortParagraphCss};
    font-weight: bold;
    color: ${({ theme }) => theme.primary500};
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.neutral200};
    border-radius: 4px;
    width: 100%;
`;
