import React from "react";

import { ProtectedRoute } from "@wayhome-uk/components";
import { useAuth } from "@wayhome-uk/utils";
import { Route, Switch } from "react-router-dom";

import { routes } from "config/routes";
import { EmailScheduler, LoginPage } from "pages";

export const Routes = () => {
    const { isLoggedIn } = useAuth();

    return (
        <Switch>
            <ProtectedRoute
                path="/"
                redirectRoute="/login"
                exact={true}
                component={EmailScheduler}
                isLoggedIn={isLoggedIn}
            />
            <Route path={routes.login.path} exact={true} component={LoginPage} />
            <Route>
                <div>Page not found</div>
            </Route>
        </Switch>
    );
};
