import React from "react";

import { WayhomeLogoInNavbar, WayhomeLogoNavbar } from "@wayhome-uk/components";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { routes } from "config/routes";

const Menu = [
    {
        title: "Email Scheduler",
        url: `${routes.emailScheduler.path}`,
    },
];

const SignOutButton = { title: "Sign out", url: "/login" };

export const PageLayout: React.FC<IBaseComponent> = ({ className, children }) => {
    const { isLoggedIn, signOut } = useAuth();

    return (
        <div className={className} data-testid="page-layout">
            <StickyHeader>
                {isLoggedIn ? (
                    <WayhomeLogoNavbar menu={Menu} button={SignOutButton} onClick={signOut} />
                ) : (
                    <LogoWrapper>
                        <WayhomeLogoInNavbar />
                    </LogoWrapper>
                )}
            </StickyHeader>
            <PageContent>{children}</PageContent>
        </div>
    );
};

const LogoWrapper = styled.div`
    background-color: ${({ theme }) => theme.neutral700};
    padding: ${(props) => props.theme.spacing24} 0 0 ${(props) => props.theme.spacing48};
    width: 100%;
`;

const StickyHeader = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const PageContent = styled.div`
    position: relative;
    z-index: 0;
`;
