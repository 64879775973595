import React from "react";

import { GlobalStyles, Theme } from "@wayhome-uk/design-system";
import { AuthProvider } from "@wayhome-uk/utils";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "routes";
import { ThemeProvider } from "styled-components";

import { ErrorBoundary } from "components/error-boundary";
import { PageLayout } from "components/page-layout";

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <AuthProvider>
                <GlobalStyles />
                <ErrorBoundary>
                    <Router>
                        <PageLayout>
                            <Routes />
                        </PageLayout>
                    </Router>
                </ErrorBoundary>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
