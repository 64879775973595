export const routes = {
    emailScheduler: {
        path: "/",
        sitemap: false,
    },
    login: {
        path: "/login",
        sitemap: false,
    },
};
