import React from "react";

import { Button, ShortLead, ShortParagraph, VerticalSpacing } from "@wayhome-uk/design-system";
import styled from "styled-components";

interface ConfirmationProps {
    onClickOk: () => void;
    onClickCancel: () => void;
    scheduleDate: string;
    customerIds: string;
}

interface SuccessProps {
    onClickOk: () => void;
}

interface ErrorProps {
    onClickOk: () => void;
}

export const formatDate = (date: string) => {
    if (!date) {
        return "unknown date";
    }
    const splitDate = date.split("-");
    const day = splitDate[2];
    const month = splitDate[1];
    const year = splitDate[0];
    return `${day}/${month}/${year}`;
};

export const formatTime = (time: string) => {
    if (!time) {
        return "unknown time";
    }
    const splitTime = time.split(":");
    const hour = splitTime[0];
    const minutes = splitTime[1];
    const period = parseInt(hour) >= 12 ? "pm" : "am";
    return `${hour}:${minutes}${period}`;
};

export const EmailSchedulerConfirmation: React.FC<ConfirmationProps> = ({
    onClickOk,
    onClickCancel,
    scheduleDate,
    customerIds,
}) => {
    const splitCustomerIds = customerIds.match(/[^\r\n]+/g) || ["", ""];
    const numberOfCustomerIds = splitCustomerIds.length;
    const splitDate = scheduleDate.split("T");
    const date = formatDate(splitDate[0]);
    const time = formatTime(splitDate[1]);

    return (
        <Wrapper data-testid="confirm-popup">
            <ShortLead as="h2">
                {`You are about to schedule ${numberOfCustomerIds} emails to be sent on ${date} at ${time}`}
            </ShortLead>
            <Text>Do you want to proceed?</Text>

            <Button data-testid="post-schedule-email-data-btn" type="button" onClick={onClickOk}>
                Yes
            </Button>
            <VerticalSpacing size={16} />
            <Button colorScheme="secondary" type="button" onClick={onClickCancel}>
                No
            </Button>
        </Wrapper>
    );
};

export const EmailSchedulerProcessingMessage: React.FC = () => {
    return (
        <Wrapper data-testid="processing-popup">
            <ShortLead as="h2">Processing...</ShortLead>
        </Wrapper>
    );
};

export const EmailSchedulerSuccessMessage: React.FC<SuccessProps> = ({ onClickOk }) => {
    return (
        <Wrapper data-testid="success-popup">
            <ShortLead as="h2">You have successfully scheduled your emails.</ShortLead>
            <VerticalSpacing size={24} />
            <Button data-testid="success-popup-close-btn" type="button" onClick={onClickOk}>
                OK
            </Button>
        </Wrapper>
    );
};

export const EmailSchedulerErrorMessage: React.FC<ErrorProps> = ({ onClickOk }) => {
    return (
        <Wrapper>
            <ShortLead as="h2">
                Error: something went wrong with the email service. <br />
            </ShortLead>
            <VerticalSpacing size={8} />
            <Text>Please speak to a human.</Text>

            <Button colorScheme="secondary" onClick={onClickOk} type="button">
                OK
            </Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 60px 40px;
    border-radius: 8px;
    max-width: 500px;
    box-shadow: 0px -2px 20px 2px rgba(0, 0, 0, 0.4);
`;

const Text = styled(ShortParagraph)`
    padding-top: 10px;
    margin-bottom: ${({ theme }) => theme.spacing24};
`;
